import React from "react";
import { Flex, Image, Box, Text } from "@chakra-ui/react";
import { IHome } from "../../App";
import Logo from "../../utilities/montania-logo.svg";

const LandingSection: React.FunctionComponent<IHome> = ({ data }) => {
  const [number, setNumber] = React.useState(0);

  const renderImages = () => {
    return data?.hauptSchieberegler?._image.map(
      (img: string, index: number) => {
        return (
          <Image
            filter="grayscale(1)"
            transition="all 0.8s"
            opacity={index === number ? "1" : "0"}
            key={index + img}
            objectFit="cover"
            position="absolute"
            top="0"
            left="0"
            src={img}
            w="100%"
            h="100%"
          />
        );
      }
    );
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const imageChange = () => {
    setInterval(() => {
      if (!data?.hauptSchieberegler?._image.length) return;
      setNumber(number + 1);
      if (number >= data?.hauptSchieberegler?._image.length - 1) {
        setNumber(0);
      }
    }, 7000);
  };

  React.useEffect(() => {
    imageChange();
  }, [data, imageChange]);

  return (
    <Flex
      w="100%"
      h="100vh"
      minH="700px"
      position="relative"
      justifyContent="center"
      alignItems="center"
    >
      <Box
        userSelect="none"
        w="100%"
        zIndex="1"
        h="100%"
        position="absolute"
        top="0"
        transition="all 0.5s"
        left="0"
        className="home-pageimg-bg"
      >
        {renderImages()}
      </Box>
      <Flex
        className="header"
        zIndex="5"
        w="100%"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        gap="10px"
        color="white"
        padding={{ base: "10px", md: "40px" }}
        fontWeight="bold"
      >
        <Image
          pointerEvents="none"
          transform={{ base: "scale(0.6)", md: "scale(0.8)", lg: "scale(1)" }}
          src={Logo}
        />

        <Text
          marginTop={{ base: "-30px", md: "0" }}
          fontSize={{ base: "25px", md: "34px", lg: "52px" }}
        >
          CORPS MONTANIA
        </Text>
        <Text
          marginTop={{ base: "-10px", md: "-10px", lg: "-20px" }}
          fontSize={{ base: "15px", md: "22px", lg: "32px" }}
          textAlign="center"
        >
          DAS CORPS IN CLAUSTHAL – SEIT 1868!
        </Text>
      </Flex>
    </Flex>
  );
};

export default LandingSection;
