import React from "react";
import { Flex, Image, Box, Text } from "@chakra-ui/react";
import { motion } from "framer-motion";
import { IHome } from "../../App";
import Fahne from "../../utilities/fahne.svg";
import FahneMobile from "../../utilities/fahneMobile.svg";
import DecorationGrüßWortLinks from "../../utilities/decorationGrüßWortLinks.svg";
import DecorationGrüßWortRecht from "../../utilities/decorationGrüßWortRecht.svg";
import DecorationGrüßWortLinksMobile from "../../utilities/decorationGrüßWortLinksMobile.svg";
import DecorationGrüßWortReachtMobile from "../../utilities/decorationGrüßWortRechtMobile.svg";
import { Link } from "react-router-dom";

const GrüßWort: React.FunctionComponent<IHome> = ({ data, windowSize }) => {
  return (
    <Flex
      w="100%"
      marginTop="10px"
      flexDirection="column"
      position="relative"
      justifyContent="center"
      gap={{ base: "0", md: "0px", lg: "40px", xl: "60px" }}
      alignItems="center"
      padding={{ base: "40px 10px", md: "40px 50px", lg: "40px 150px" }}
      overflow="hidden"
      className="example"
    >
      <Image
        src={windowSize && windowSize?.width > 768 ? Fahne : FahneMobile}
        transform={{ base: "scale(1)", md: "scale(0.8)", lg: "scale(1)" }}
        zIndex="5"
      />
      <motion.div
        initial={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          zIndex: "3",
          height: "100%",
          background: "#E8E8E8",
        }}
        whileInView={{ height: "0" }}
        transition={{ duration: 1.6, stiffness: 120 }}
      ></motion.div>
      <Image
        transform={{
          base: "scale(1)",
          md: "scale(2.6)",
          lg: `scale(${windowSize && windowSize?.width / 1920})`,
        }}
        // transform="scale(0.8)"
        top={{ base: "10px", md: "90px", lg: "-20px", xl: "0" }}
        left={{
          base: "20px",
          md: "90px",
          lg:
            windowSize && windowSize.width < 1700 && windowSize.width > 1351
              ? `-${windowSize && (1920 / windowSize?.width) * 40}px`
              : windowSize && windowSize.width < 1350
              ? `-${windowSize && (1920 / windowSize?.width) * 100}px`
              : "0",
        }}
        zIndex="1"
        position="absolute"
        src={
          windowSize && windowSize?.width > 991
            ? DecorationGrüßWortLinks
            : DecorationGrüßWortLinksMobile
        }
      />

      <Image
        transform={{
          base: "scale(1)",
          md: "scale(2.6)",
          lg: `scale(${windowSize && windowSize?.width / 1920})`,
        }}
        top={{ base: "10px", md: "90px", lg: "-20px", xl: "0" }}
        right={{
          base: "20px",
          md: "90px",
          lg:
            windowSize && windowSize.width < 1700 && windowSize.width > 1351
              ? `-${windowSize && (1920 / windowSize?.width) * 40}px`
              : windowSize && windowSize.width < 1350
              ? `-${windowSize && (1920 / windowSize?.width) * 100}px`
              : "0",
        }}
        position="absolute"
        zIndex="1"
        src={
          windowSize && windowSize?.width > 991
            ? DecorationGrüßWortRecht
            : DecorationGrüßWortReachtMobile
        }
      />

      <Box
        zIndex="5"
        color="#707070"
        fontSize={{ base: "12px", md: "16px", lg: "24px" }}
        maxW={{
          base: "auto",
          md: "500px",
          lg: "700px",
          xl: "800px",
          "2xl": "900px",
        }}
      >
        {data?.grüßwort?.text.split(".").map((text: any, index: any) => {
          return (
            <motion.p
              initial={{
                opacity: 0,
                wordBreak: "break-word",
                textAlign: "center",
                y: -200,
                scale: 0,
                fontWeight:
                  index === data?.grüßwort?.text.split(".").length - 1
                    ? 800
                    : 200,
              }}
              whileInView={{ opacity: 1, y: 0, scale: 1 }}
              transition={{ duration: 1.2, stiffness: 120 }}
              key={index}
            >
              {" "}
              {text} <br />
            </motion.p>
          );
        })}
      </Box>
      <Link to="/geschichte-des-corps">
        <Text
          marginTop="10px"
          fontSize={{ base: "14px", md: "18px", lg: "24px" }}
          fontStyle="italic"
          borderBottom="1px #2D84B2 solid "
          className="text-hover"
          color="#2D84B2"
          cursor="pointer"
        >
          Mehr Über Das Corps Erfahren
        </Text>
      </Link>
    </Flex>
  );
};

export default GrüßWort;
