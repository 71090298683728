import React from "react";

import { Flex, Text } from "@chakra-ui/react";
import { motion } from "framer-motion";

interface IHead {
  name?: string;
}
const Header: React.FunctionComponent<IHead> = ({ name }) => {
  return (
    <Flex
      w="100%"
      overflow="hidden"
      justifyContent="center"
      alignItems="center"
      padding="20px"
    >
      <motion.div
        initial={{
          opacity: 0,
        }}
        whileInView={{ opacity: 1 }}
        transition={{ duration: 1.6, stiffness: 120 }}
      >
        <Text
          fontFamily="serif"
          className="color-bg"
          wordBreak="break-word"
          fontWeight="bold"
          fontSize={{ base: "22px", md: "30px", lg: "40px", xl: "55px" }}
        >
          {name}
        </Text>
      </motion.div>
    </Flex>
  );
};

export default Header;
