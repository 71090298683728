import React from "react";
import { IHome } from "../../App";

import { Flex, Image, Box, Text } from "@chakra-ui/react";
import Header from "../shared/Header";
import LeitlinienImg from "../../utilities/Leitlinien.png";

import { motion } from "framer-motion";

const UnsereLeitlinien: React.FunctionComponent<IHome> = ({ data }) => {
  const renderData = () => {
    return data?.unsereLeitlinien.map(
      (
        element: {
          beschreibung: string | undefined;
          title: string | undefined;
        },
        index: number
      ) => {
        return (
          <AGuid
            number={index + 1}
            key={index}
            desc={element.beschreibung}
            title={element.title}
          />
        );
      }
    );
  };
  return (
    <Flex
      marginTop="40px"
      flexDirection="column"
      w="100%"
      justifyContent="center"
      gap="20px"
    >
      <Header name="Unsere Leitlinien" />
      <Box position="relative" className="home-pageimg-bg-ziel-img">
        <Image minH="260px" objectFit="cover" src={LeitlinienImg} />
      </Box>
      <Flex
        padding={{ base: "0 20px", md: "0 30px", lg: "0 40px" }}
        w="100%"
        flexDirection="column"
        gap="20px"
        justifyContent="center"
        alignItems="center"
      >
        {renderData()}
      </Flex>
    </Flex>
  );
};

export default UnsereLeitlinien;

interface IAGuid {
  title?: string;
  desc?: string;
  number?: number;
}
export const AGuid: React.FunctionComponent<IAGuid> = ({
  desc,
  title,
  number,
}) => {
  return (
    <Flex
      w="100%"
      maxW="900px"
      flexDirection="column"
      gap="20px"
      alignItems="center"
    >
      <motion.div
        initial={{
          opacity: 0,
          scale: 0.7,
          textAlign: "center",
        }}
        whileInView={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.6, stiffness: 120 }}
      >
        <Text
          fontSize={{ base: "14px", md: "20px", lg: "30px" }}
          color="#000000"
          fontWeight="bold"
          className="color-bg"
        >
          {number}
          {"-"} {title}
        </Text>
      </motion.div>
      <motion.div
        initial={{
          opacity: 0,
          y: 100,
          textAlign: "center",
        }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6, stiffness: 120 }}
      >
        <Text
          textAlign="center"
          fontSize={{ base: "12px", md: "16px", lg: "24px" }}
          color="#707070"
        >
          {desc}
        </Text>
      </motion.div>
    </Flex>
  );
};
