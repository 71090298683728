import React from "react";

import { Flex, Image, Box } from "@chakra-ui/react";
import Header from "../shared/Header";
import Freundschaften from "../../utilities/zieleUndErwartungen/Freundschaften.png";
import Fortführen from "../../utilities/zieleUndErwartungen/IMG_1210-scaled.jpg";
import Bildung from "../../utilities/zieleUndErwartungen/DSC_1545.png";
import Gemeinschaft from "../../utilities/zieleUndErwartungen/gemeinschaft.png";
import Vielfalt from "../../utilities/zieleUndErwartungen/vielfalt.png";

import { motion } from "framer-motion";
import { ISlider } from "../../App";
const ZielUndErwartungen: React.FunctionComponent<ISlider> = (props) => {
  return (
    <Flex
      flexDirection="column"
      w="100%"
      justifyContent="center"
      gap={{ base: "5px", md: "10px", xl: "20px" }}
    >
      <Header name="Ziele und Erwartungen" />
      {props.windowSize && props.windowSize.width > 768 ? (
        <>
          <Flex
            w="100%"
            justifyContent="center"
            gap={{ base: "5px", md: "10px", xl: "20px" }}
            flexWrap={{ base: "wrap", md: "nowrap" }}
          >
            <EinZiel
              img={Freundschaften}
              text="Freundschaften über Generationen"
            />
            <EinZiel
              maxW="402px"
              img={Fortführen}
              text="Fortführen der Tradition"
            />
            <EinZiel img={Bildung} text="Bildung" />
          </Flex>
          <Flex
            w="100%"
            justifyContent="center"
            gap={{ base: "5px", md: "10px", xl: "20px" }}
            flexWrap={{ base: "wrap", md: "nowrap" }}
          >
            <EinZiel img={Gemeinschaft} text="Gemeinschaft" />
            <EinZiel img={Vielfalt} text="Vielfalt" />
          </Flex>
        </>
      ) : (
        <>
          <Flex
            w="100%"
            justifyContent="center"
            gap={{ base: "5px", md: "10px", xl: "20px" }}
            flexWrap={{ base: "wrap", md: "nowrap" }}
          >
            <EinZiel
              w={{ base: "100%", md: "auto" }}
              img={Freundschaften}
              text="Freundschaften über Generationen"
            />
            <EinZiel
              w={{ base: "calc(55% - 5px)", md: "auto" }}
              img={Gemeinschaft}
              text="Gemeinschaft"
            />
            <EinZiel
              w={{ base: "calc(45% - 5px)", md: "auto" }}
              img={Bildung}
              text="Bildung"
            />
            <EinZiel
              w={{ base: "calc(45% - 5px)", md: "auto" }}
              img={Vielfalt}
              text="Vielfalt"
            />
            <EinZiel
              w={{ base: "calc(55% - 5px)", md: "auto" }}
              maxW="402px"
              img={Fortführen}
              text="Fortführen der Tradition"
            />
          </Flex>
        </>
      )}
    </Flex>
  );
};

export default ZielUndErwartungen;

interface IZiel {
  img: string;
  text: string;
  maxW?: any;
  w?: any;
}
export const EinZiel: React.FunctionComponent<IZiel> = ({
  img,
  text,
  maxW,
  w,
}) => {
  return (
    <Flex
      maxW={maxW}
      w={w}
      overflow="hidden"
      className="home-pageimg-bg-ziel"
      position="relative"
    >
      <Image transition="all 0.5s" objectFit="cover" src={img} />
      <Box
        zIndex="3"
        fontSize={{ base: "12px", md: "16px", lg: "22px", xl: "26px" }}
        fontWeight="bold"
        color="#F9F9F9"
        position="absolute"
        top="50%"
        left="50%"
        transform="translate(-50% , -50%)"
      >
        <motion.p
          initial={{
            opacity: 0,
            scale: 0.7,
            textAlign: "center",
          }}
          whileInView={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.7, stiffness: 120 }}
        >
          {text}
        </motion.p>
      </Box>
    </Flex>
  );
};
