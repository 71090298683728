import React from "react";
import { IData } from "../../App";

import { Flex, Box, Image, Input, Text, Textarea } from "@chakra-ui/react";
import Header from "../shared/Header";
import ContactUsImage from "../../utilities/contact.png";
import axios from "axios";

import { motion } from "framer-motion";
interface IForm {
  setMessage: React.Dispatch<
    React.SetStateAction<{
      email: string;
      name: string;
      message: string;
    }>
  >;
  message: {
    email: string;
    name: string;
    message: string;
  };
}

const Kontankt: React.FunctionComponent<IData> = (props) => {
  const [message, setMessage] = React.useState<IForm["message"]>({
    email: "",
    name: "",
    message: "",
  });
  const [sucsses, setSucsses] = React.useState<boolean>(false);
  const [err, setErr] = React.useState<string>("");

  const submit = (e: any) => {
    const newMessges: any = { ...message };
    newMessges[e.target.id] = e.target.value;
    setMessage(newMessges);
  };

  const submitMessage = async (e: any) => {
    e.preventDefault();
    if (
      message.email.length <= 1 &&
      message.message.length <= 1 &&
      message.name.length <= 1
    ) {
      setErr("Bitte füllen Sie alles aus");
      setTimeout(() => {
        setErr("");
      }, 4000);

      return;
    }
    await axios
      .post(
        "https://client.orkabit.com/api/v1/contact-us?domainName=corps-montania.de",
        {
          email: message.email,
          name: message.name,
          message: message.message,
        }
      )
      .catch((err) => {
        console.log(err.response.data);
      })
      .finally(function () {
        wordPlay();
        setTimeout(() => {
          setMessage({ email: "", name: "", message: "" });
        }, 3000);
      });
  };

  const wordPlay = () => {
    setSucsses(true);
    setTimeout(() => {
      setSucsses(false);
    }, 5000);
  };

  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      gap="30px"
      flexDirection="column"
      marginTop="30px "
      marginBottom="60px "
      w="100%"
    >
      <Header name="Kontaktiere uns" />
      <Flex
        gap="40px"
        position="relative"
        w="100%"
        maxW="1200px"
        boxShadow="rgba(0,0,0,0.19) 0 3px 6px 1px"
        flexDirection="column"
      >
        <Box position="relative" className="home-pageimg-bg-ziel-img ">
          <Image minH="140px" objectFit="cover" src={ContactUsImage} />
        </Box>
        <motion.form
          initial={{
            width: "100%",

            background: "#E8E8E8",

            display: "flex",
            flexDirection: "column",
            gap: "40px",
            justifyContent: "center",
            alignItems: "center",

            opacity: 0,
            paddingBottom: 40,
          }}
          whileInView={{
            opacity: 1,
          }}
          transition={{ duration: 1 }}
        >
          <Flex
            padding="0 20px "
            alignItems="center"
            maxW="650px"
            justifyContent="space-between"
            w="100%"
            gap="15px"
          >
            <Text
              w={{ base: "80px", md: "150px" }}
              flexShrink="0"
              textAlign="center"
              fontSize={{ base: "14px", md: "18px", xl: "22px" }}
              fontWeight="600"
            >
              Name
            </Text>{" "}
            <Input
              maxW="500px"
              required
              id="name"
              value={message.name}
              onChange={(e) => submit(e)}
              border="none"
              borderBottom="2px solid  #000000"
              padding="10px"
              borderRadius="0"
              w="85%"
              placeholder="Vorname Nachname"
            />
          </Flex>
          <Flex
            padding="0 20px "
            alignItems="center"
            maxW="650px"
            justifyContent="space-between"
            w="100%"
            gap="15px"
          >
            <Text
              w={{ base: "80px", md: "150px" }}
              flexShrink="0"
              textAlign="center"
              fontSize={{ base: "14px", md: "18px", xl: "22px" }}
              fontWeight="600"
            >
              E-mail
            </Text>{" "}
            <Input
              maxW="500px"
              id="email"
              onChange={(e) => submit(e)}
              value={message.email}
              required
              border="none"
              borderRadius="0"
              borderBottom="2px solid  #000000"
              padding="10px"
              w="85%"
              placeholder=" E-mail"
            />
          </Flex>
          <Flex
            padding="0 20px "
            alignItems="flex-start"
            maxW="650px"
            justifyContent="space-between"
            w="100%"
            gap="15px"
          >
            <Text
              w={{ base: "80px", md: "150px" }}
              flexShrink="0"
              textAlign="center"
              fontSize={{ base: "14px", md: "18px", xl: "22px" }}
              fontWeight="600"
            >
              Nachricht
            </Text>{" "}
            <Textarea
              maxW="500px"
              id="message"
              required
              value={message.message}
              onChange={(e) => submit(e)}
              border="none"
              borderRadius="0"
              borderBottom="2px solid  #000000"
              padding="10px"
              w="85%"
              placeholder="Nachricht"
            />
          </Flex>
          <Box
            cursor="pointer"
            className="text-hover"
            justifyContent="center"
            alignItems="center"
            bg={
              err.length !== 0
                ? "red.600"
                : "linear-gradient(to left, #3A7EA4, #984233, #C1ABA0)"
            }
            color="white"
            borderRadius="40px"
            fontSize={{ base: "11px", md: "14px", lg: "17px" }}
            onClick={submitMessage}
            fontWeight="bold"
            padding="12px 35px"
          >
            {/* {err.length !== 0 && err} */}
            {sucsses && err.length === 0 ? (
              "  Danke für Ihre Nachricht "
            ) : err.length !== 0 ? (
              err
            ) : (
              <>Nachricht senden</>
            )}
          </Box>{" "}
        </motion.form>
      </Flex>
    </Flex>
  );
};

export default Kontankt;
