import React from "react";

import { motion } from "framer-motion";
import { Flex } from "@chakra-ui/react";

import { Link, useLocation } from "react-router-dom";
import { IData, ISlider } from "../../App";

interface INavbar extends IData, ISlider {}

const Navabr: React.FunctionComponent<INavbar> = ({ data, windowSize }) => {
  const { pathname } = useLocation();

  return (
    <Flex
      zIndex="11"
      color={pathname === "/" ? "#E4E4E4" : "#000000"}
      w="100%"
      position="absolute"
      top={{ base: "30px", md: "60px" }}
      left="0"
      justifyContent="center"
      textTransform="uppercase"
      gap={{ base: "10px", md: "30px", lg: "30px", xl: "80px", "2xl": "100px" }}
      fontSize={{
        base: "8px",
        md: "12px",
        lg: "15px",
        xl: "16px",
        "2xl": "20px",
      }}
      fontWeight="bold"
      className="navbar"
    >
      <Link
        to={
          pathname === "/geschichte-des-corps" ? "/" : "/geschichte-des-corps"
        }
      >
        <motion.p
          initial={{
            y: -100,
          }}
          animate={{ y: 0 }}
          transition={{ duration: 0.6, stiffness: 120 }}
        >
          {pathname === "/geschichte-des-corps" ? "Montania" : "Über Uns"}
        </motion.p>
      </Link>
      <Link to={pathname === "/unser-wappen" ? "/" : "/unser-wappen"}>
        <motion.p
          initial={{
            y: -100,
          }}
          animate={{ y: 0 }}
          transition={{ duration: 0.6, stiffness: 120 }}
        >
          {pathname === "/unser-wappen" ? "Montania" : "unser-wappen"}
        </motion.p>
      </Link>
      <Link to={pathname === "/unser-haus" ? "/" : "/unser-haus"}>
        <motion.p
          initial={{
            y: -100,
          }}
          animate={{ y: 0 }}
          transition={{ duration: 0.6, stiffness: 120 }}
        >
          {pathname === "/unser-haus" ? "Montania" : "Unser Haus"}
        </motion.p>
      </Link>
      <Link to={pathname === "/wohnungen" ? "/" : "/wohnungen"}>
        <motion.p
          initial={{
            y: -100,
          }}
          animate={{ y: 0 }}
          transition={{ duration: 0.6, stiffness: 120 }}
        >
          {pathname === "/wohnungen" ? "Montania" : "Wohnungen"}
        </motion.p>
      </Link>
      <a href={data?.semsterProgramm._pdf} download>
        <motion.p
          initial={{
            y: -100,
            pointerEvents: "none",
          }}
          animate={{ y: 0 }}
          transition={{ duration: 0.6, stiffness: 120 }}
        >
          Semesterprogramm
        </motion.p>
      </a>
    </Flex>
  );
};

export default Navabr;
