import React from "react";
import { IData } from "../../App";

import { motion } from "framer-motion";
import { Flex, Text } from "@chakra-ui/react";
import Header from "../shared/Header";
const SemsterProgramm: React.FunctionComponent<IData> = ({ data }) => {
  return (
    <Flex
      margin="50px 0"
      justifyContent="center"
      alignItems="center"
      gap="30px"
      flexDirection="column"
      overflow="hidden"
      h={{ base: "250px", md: "270px", lg: "350px" }}
      w="100%"
      position="relative"
    >
      <motion.div
        initial={{
          position: "absolute",
          top: 0,

          scaleX: 0,
          width: "calc(100% + 10px)",
          height: "33px",
          boxShadow: "inset 0px -6px 8px -2px #9a4535",
        }}
        whileInView={{
          scaleX: 1,
        }}
        transition={{ duration: 0.7, stiffness: 120 }}
      />
      <motion.div
        initial={{
          position: "absolute",
          bottom: 0,

          scaleX: 0,
          width: "calc(100% + 10px)",
          height: "33px",
          boxShadow: "inset 0px 6px 8px -2px #9a4535",
        }}
        whileInView={{
          scaleX: 1,
        }}
        transition={{ duration: 0.7, stiffness: 120 }}
      />

      <Header name="Semesterprogramm" />
      <a href={data?.semsterProgramm._pdf} download>
        <Flex
          marginBottom="10px"
          cursor="pointer"
          className="text-hover"
          justifyContent="center"
          alignItems="center"
          bg="linear-gradient(to left, #3A7EA4, #984233, #C1ABA0)"
          color="white"
          borderRadius="40px"
          w={{ base: "150px", md: "220px", lg: "300px" }}
          h={{ base: "36px", md: "45px", lg: "65px" }}
          fontSize={{ base: "12px", md: "16px", lg: "24px" }}
        >
          <Text>Download PDF</Text>
        </Flex>
      </a>
      {/* <Box h="1000px"></Box> */}
    </Flex>
  );
};

export default SemsterProgramm;
