import React from "react";
import { Box, Flex, Image } from "@chakra-ui/react";

import { Link } from "react-router-dom";

import Logo from "../../utilities/montania-logo.svg";
interface IChildren {
  children: any;
}
const Container: React.FunctionComponent<IChildren> = (props) => {
  return (
    <Flex
      flexDirection="column"
      gap="20px"
      padding={{ base: "90px 0", md: "110px 0", lg: "150px 0" }}
      w="100%"
      //   h="100vh"
      minH="700px"
      position="relative"
      justifyContent="center"
      alignItems="center"
    >
      <Link to="/">
        {" "}
        <Image
          cursor="pointer"
          display={{ base: "none", md: "block" }}
          //   pointerEvents="none"
          transform={{
            base: "scale(0.6)",
            md: "scale(0.4) translateY(-50%)",
            lg: "scale(0.6) translateY(-50%)",
          }}
          src={Logo}
          position="absolute"
          top={{ md: "30px", lg: "60px" }}
          left={{ md: "30px", xl: "70px" }}
        />
      </Link>
      <Box
        pointerEvents="none"
        w="100%"
        bg="linear-gradient(to bottom, rgba(49,129,172,0.2), rgba(168,42,15,0.2), rgba(194,173,163,0.2))"
        position="fixed"
        h="100%"
        top="0"
        left="0"
      />
      {props.children}
    </Flex>
  );
};

export default Container;
