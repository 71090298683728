import axios from "axios";
import React from "react";
import { Route, useLocation } from "react-router";
import { Routes } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";

import LandingSection from "./components/homePage/LandingSection";
import Navabr from "./components/layout/Navabr";
import GrüßWort from "./components/homePage/GrüßWort";
import ZielUndErwartungen from "./components/homePage/ZielUndErwartungen";
import UnsereLeitlinien from "./components/homePage/UnsereLeitlinien";
import SemsterProgramm from "./components/homePage/SemsterProgramm";

import Kontankt from "./components/homePage/Kontankt";
import Footer from "./components/layout/Footer";
import Gallery from "./components/homePage/Gallery";
import Whonungen from "./components/Wohnungen/Wohnungen";
import Container from "./components/shared/Container";

const GeschichteDesCorps = React.lazy(() =>
  import("./components/geschichte-des-corps/GeschichteDesCorps")
);
const UnserHause = React.lazy(() => import("./components/haus/UnserHause"));
const UnserWappen = React.lazy(() =>
  import("./components/unser-wappen/UnserWappen")
);
const GalleryPage = React.lazy(() =>
  import("./components/gallery/GalleryPage")
);

export interface ISlider {
  windowSize?: {
    width: number;
    height: number;
  };
}
export interface IData {
  data?: any;
}

function App() {
  const [windowSize, setWindowSize] = React.useState<ISlider["windowSize"]>({
    width: 0,
    height: 0,
  });
  function handleResize() {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  }
  React.useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("resize", handleResize);

      handleResize();

      return () => window.removeEventListener("resize", handleResize);
    }
  }, []);

  const [data, setData] = React.useState(null);
  const getHomeData = async () => {
    await axios
      .get(
        "https://client.orkabit.com/api/v1/page?route=/&domain=corps-montania.de"
      )
      .then(function(response: any) {
        setData(response.data.page_data);
      })
      .catch(function(error: any) {
        console.log(error);
      })
      .finally(function() {});
  };

  React.useEffect(() => {
    getHomeData();
  }, []);

  const location: any = useLocation();

  return (
    <>
      <Navabr windowSize={windowSize} data={data} />
      <AnimatePresence
        onExitComplete={() => window.scroll(0, 0)}
        exitBeforeEnter
      >
        <Routes location={location} key={location.pathname}>
          <Route
            path="/"
            element={
              <AnimateDev>
                <HomePage data={data} windowSize={windowSize} />
              </AnimateDev>
            }
          />
          <Route
            path="/geschichte-des-corps"
            element={
              <AnimateDev>
                <GeschichteDesCorps />
              </AnimateDev>
            }
          />
          <Route
            path="/unser-wappen"
            element={
              <AnimateDev>
                <UnserWappen />
              </AnimateDev>
            }
          />
          <Route
            path="/unser-haus"
            element={
              <AnimateDev>
                <UnserHause />
              </AnimateDev>
            }
          />
          <Route
            path="/gallery"
            element={
              <AnimateDev>
                <GalleryPage data={data} />
              </AnimateDev>
            }
          />
          <Route
            path="/wohnungen"
            element={
              <AnimateDev>
                <Whonungen />
              </AnimateDev>
            }
          />
          <Route
            path="*"
            element={
              <Container>
                <h1 style={{ fontWeight: "bold", fontSize: "24px" }}>
                  404 not found
                </h1>
              </Container>
            }
          />
        </Routes>
      </AnimatePresence>
      <Footer data={data} />
    </>
  );
}

export default App;

export interface IHome extends IData, ISlider {}
export const HomePage: React.FC<IHome> = ({
  windowSize,

  data,
}) => {
  return (
    <>
      <LandingSection data={data} />
      <GrüßWort windowSize={windowSize} data={data} />
      <ZielUndErwartungen windowSize={windowSize} />
      <UnsereLeitlinien data={data} />
      <SemsterProgramm data={data} />
      <Gallery data={data} />
      <Kontankt data={data} />
    </>
  );
};

export interface IChildren {
  children?: JSX.Element;
}
export const AnimateDev: React.FC<IChildren> = ({ children }) => {
  return (
    <motion.div
      initial={{
        opacity: 0,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
    >
      {children}
    </motion.div>
  );
};
