import React from "react";
import { Flex, Image, Text } from "@chakra-ui/react";

import Logo from "../../utilities/montania-logo.svg";

import Fahne from "../../utilities/fahne.svg";
import { IData } from "../../App";
import Facebook from "../../utilities/facebook.svg";
import Instagram from "../../utilities/instagram.svg";

const Footer: React.FunctionComponent<IData> = (props) => {
  return (
    <Flex
      w="100%"
      gap="20px"
      flexDirection={{ base: "column", md: "row" }}
      justifyContent="space-evenly"
      alignItems="center"
      padding={{
        base: "5px 5px 40px 5px",
        md: "10px 10px 40px 10px ",
        lg: "30px 30px 40px 30px",
      }}
      position="relative"
    >
      <Flex alignItems="center">
        <Image
          pointerEvents="none"
          transform={{ base: "scale(0.6)", md: "scale(0.5)", lg: "scale(0.6)" }}
          src={Logo}
        />
        <Flex
          fontSize={{ base: "12px", md: "16px", lg: "18px" }}
          gap="0px"
          flexDirection="column"
        >
          <Text fontWeight="bold"> {props.data?.kontakt.adresse} </Text>
          <Text>
            <span style={{ fontWeight: "bold" }}>Email:</span>{" "}
            <a href={`mailto:${props.data?.kontakt.email}`}>
              {props.data?.kontakt.email}{" "}
            </a>
          </Text>
          <Text>
            {" "}
            <span style={{ fontWeight: "bold" }}>Phone:</span>{" "}
            <a href={`tel:${props.data?.kontakt.nummer}`}>
              {props.data?.kontakt.nummer}
            </a>{" "}
          </Text>
        </Flex>
      </Flex>
      <Image
        src={Fahne}
        opacity={{ base: "0.1", md: "0.4" }}
        position={{ base: "absolute", md: "relative" }}
        top={{ base: "50%", md: "unset" }}
        left={{ base: "50%", md: "unset" }}
        transform={{
          base: "scale(1)   translate(-50%,-50%)",
          md: "scale(0.6)",
          lg: "scale(1)",
        }}
        // zIndex="5"
      />
      <Flex flexDirection="column" gap="20px" alignItems="center">
        <Text
          fontWeight="bold"
          color="#4182AA"
          fontSize={{ base: "12px", md: "15px", lg: "20px" }}
        >
          Bleibe auf dem Laufenden
        </Text>
        <Flex gap="30px">
          <a href="https://orkabit.com/">
            <Image cursor="pointer" src={Facebook} />
          </a>
          <a href="https://www.instagram.com/corpsmontania/?hl=en">
            <Image cursor="pointer" src={Instagram} />
          </a>
        </Flex>
        <Text fontSize={{ base: "9px", md: "11px", lg: "13px" }}>
          Copyright © {new Date().getFullYear()} Corps Montania Privacy Policy |
          Powered by{" "}
          <span style={{ fontWeight: "bold" }}>
            {" "}
            <a href="https://orkabit.com/">ORKABIT</a>{" "}
          </span>
        </Text>
      </Flex>
    </Flex>
  );
};

export default Footer;
