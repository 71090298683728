import React from "react";
import Container from "../shared/Container";
import {
  Box,
  Flex,
  Grid,
  GridItem,
  Image,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  useMediaQuery,
} from "@chakra-ui/react";
import Slider from "react-slick";
import Header from "../shared/Header";
import axios from "axios";
import Icon from "./address.svg";
import "./slick.css";
import "./slick-theme.css";
// import "react-multi-carousel/lib/styles.css";

const Wohnungen: () => JSX.Element = () => {
  const [data, setData] = React.useState<any>(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isMobile] = useMediaQuery("(max-width: 767px)");

  const getData = async () => {
    await axios
      .get(
        "https://client.orkabit.com/api/v1/page?route=/rooms/&domain=corps-montania.de"
      )
      .then(function(response: any) {
        setData(response.data.page_data.all_rooms);
      })
      .catch(function(error: any) {
        console.log(error);
      })
      .finally(function() {});
  };

  React.useEffect(() => {
    getData();
  }, []);

  const settings = {
    dots: true,
    fade: true,
    infinite: true,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 4000,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const imageOrder: (
    index: number,
    arrayIndex: number
  ) => { row: number; col: number } = (index: number, arrayIndex: number) => {
    if (index === 0 || index === 2) {
      return { row: 8, col: 7 };
    } else if (index === 1) {
      return { row: 16, col: 14 };
    } else if (index < 10) {
      return {
        row: 5,
        col: 3,
      };
    } else {
      return {
        row: 0,
        col: 0,
      };
    }
  };

  const renderModal = (index: number) => {
    return data[index].images.map(
      (
        element_img: {
          _image: string;
          alt: string;
        },
        index_img: number
      ) => {
        return (
          <GridItem
            onClick={onOpen}
            cursor="pointer"
            rowSpan={7}
            colSpan={7}
            position="relative"
            overflow="hidden"
            zIndex="2"
          >
            <Image
              className="img-hover"
              objectFit="cover"
              key={index_img}
              w="100%"
              src={element_img._image}
              alt={element_img.alt}
            />
          </GridItem>
        );
      }
    );
  };

  const renderData = () => {
    return data?.map(
      (
        element: {
          title: string | undefined;
          price: number | undefined;
          desc: string | undefined;
          contact: { adresse: string; email: string[]; number: any[] };
          images: any[];
        },
        index: number
      ) => {
        return (
          <>
            <Flex
              key={index}
              w={{ base: "90%", md: "80%", lg: "70%" }}
              gap="10px"
              flexDirection="column"
              alignItems="flex-start"
              justifyContent="center"
            >
              <Modal
                motionPreset="scale"
                size="full"
                isOpen={isOpen}
                onClose={onClose}
              >
                <ModalOverlay />
                <ModalContent
                  bg="linear-gradient(to bottom, rgba(49,129,172,1),
                      rgba(168,42,15,1), rgba(194,173,163,1))"
                >
                  <ModalHeader color="white" textAlign="center">
                    Alle Bilder:
                  </ModalHeader>
                  <ModalCloseButton />
                  {!isMobile ? (
                    <Grid
                      templateRows="repeat(21, 1fr)"
                      templateColumns="repeat(21, 1fr)"
                      gap="15px"
                      w="100%"
                      position="relative"
                    >
                      {renderModal(index)}
                    </Grid>
                  ) : (
                    <>
                      {" "}
                      <Box margin="0 auto" h="100%" w="90%" overflow="hidden">
                        <Slider {...settings}>
                          {element?.images.length !== 0 &&
                            element?.images.map((element_img, index_img) => (
                              <Box
                                onClick={onOpen}
                                cursor="grab"
                                key={index_img}
                                bgImage={element_img._image}
                                width="90%"
                                // minH="700px"
                                h="500px"
                                overflow="hidden"
                                bgPosition="center"
                                bgSize="contain"
                                bgRepeat="no-repeat"
                                display="flex"
                                justifyContent="center"
                                alignItems="end"
                                position="relative"
                              />
                            ))}
                        </Slider>
                      </Box>
                    </>
                  )}
                  <ModalFooter>
                    <Box
                      cursor="pointer"
                      fontWeight="bold"
                      mr={3}
                      onClick={onClose}
                    >
                      Fenster Schließen
                    </Box>
                  </ModalFooter>
                </ModalContent>
              </Modal>
              <Text
                fontSize={{ base: "16px", md: "20px", lg: "24px", xl: "30px" }}
                fontWeight="bold"
              >
                {element.title}
              </Text>
              <a
                className="color-bg"
                href="https://www.google.com/maps/place/Corps+Montania+Clausthal/@51.8053065,10.339821,17z/data=!3m2!4b1!5s0x47a53bf15ee57bc9:0xcef26d63079972ed!4m6!3m5!1s0x47a53bf15e467e39:0xf8b962c34e282b4b!8m2!3d51.8053032!4d10.3423959!16s%2Fg%2F1211j94l?entry=ttu"
                target="_blank"
                rel="noreferrer"
              >
                <Flex
                  gap="10px"
                  fontSize={{
                    base: "12px",
                    md: "15px",
                    lg: "17px",
                    xl: "18px",
                  }}
                  fontWeight="bold"
                >
                  <Image objectFit="cover" w="30px" h="30px" src={Icon} />
                  <Text>{element?.contact?.adresse}</Text>
                </Flex>
              </a>
              <Text
                fontSize={{
                  base: "12px",
                  md: "15px",
                  lg: "17px",
                  xl: "18px",
                }}
                className="color-bg"
                fontWeight="bold"
                paddingBottom={{ base: "0", md: "40px" }}
              >
                {element.price} {"€"}
              </Text>
              {!isMobile ? (
                <Grid
                  // h="200px"
                  templateRows="repeat(21, 1fr)"
                  templateColumns="repeat(21, 1fr)"
                  gap="10px"
                  position="relative"
                  w="100%"
                  h="600px"
                >
                  {element?.images.length !== 0 &&
                    element?.images.map((element_img, index_img) => {
                      return (
                        index_img < 10 && (
                          <GridItem
                            onClick={onOpen}
                            cursor="pointer"
                            rowSpan={imageOrder(index_img, index).row}
                            colSpan={imageOrder(index_img, index).col}
                            position="relative"
                            overflow="hidden"
                            zIndex="2"
                            key={index_img}
                          >
                            {index_img === 9 ? (
                              <Flex
                                justifyContent="center"
                                alignItems="center"
                                top="0"
                                zIndex="3"
                                left="0"
                                w="100%"
                                h="100%"
                                bg="rgba(0,0,0,0.6)"
                                position="absolute"
                                textAlign="center"
                                fontWeight="bold"
                                fontSize="14px"
                                color="white"
                              >
                                {data[index].images.length - 9 + " Bilder"}
                              </Flex>
                            ) : (
                              <></>
                            )}
                            <Image
                              objectFit="cover"
                              boxShadow="rgba(0,0,0,0.79) 0 3px 9px 2px"
                              w="100%"
                              src={element_img._image}
                              alt={element_img.alt}
                            />
                          </GridItem>
                        )
                      );
                    })}
                </Grid>
              ) : (
                <Box h="100%" w="100%">
                  <Slider {...settings}>
                    {element?.images.length !== 0 &&
                      element?.images.map((element_img, index_img) => (
                        <Box
                          onClick={onOpen}
                          cursor="grab"
                          key={index_img}
                          bgImage={element_img._image}
                          width="100%"
                          minH="400px"
                          h="400px"
                          overflow="hidden"
                          bgPosition="center"
                          bgSize="contain"
                          bgRepeat="no-repeat"
                          display="flex"
                          justifyContent="center"
                          alignItems="end"
                          position="relative"
                        />
                      ))}
                  </Slider>
                </Box>
              )}
              <Flex
                paddingTop="40px"
                flexDirection="column"
                gap="10px"
                alignItems="flex-start"
              >
                {element.desc?.split(".").map((el: string, index: number) => {
                  return (
                    <Text
                      textAlign="start"
                      fontSize={{ base: "12px", md: "16px", lg: "20px" }}
                      key={index}
                    >
                      {el}
                    </Text>
                  );
                })}
              </Flex>
              <Flex
                flexDirection="column"
                gap="10px"
                fontWeight="bold
                "
                fontSize={{
                  base: "12px",
                  md: "15px",
                  lg: "17px",
                  xl: "18px",
                }}
              >
                <Text fontWeight="bold">
                  Haben Sie intereese , dann schreiben Sie oder rufen Sie uns an
                </Text>
                {element?.contact?.email?.map((em: string, index: number) => {
                  return (
                    <a
                      style={{ width: "fit-content" }}
                      key={index}
                      href={`mailto:${em}`}
                    >
                      <Text
                        color="#3082AE"
                        fontSize={{
                          base: "10px",
                          md: "12px",
                          lg: "14px",
                          xl: "15px",
                        }}
                      >
                        {em}
                      </Text>
                    </a>
                  );
                })}
                {element?.contact?.number.length !== 0 &&
                  element?.contact?.number[0].length > 5 &&
                  element?.contact?.number?.map((nm: any, index: number) => {
                    return (
                      <a
                        style={{ width: "fit-content" }}
                        key={index}
                        href={`tel:${nm}`}
                      >
                        <Text
                          color="#3082AE"
                          fontSize={{
                            base: "10px",
                            md: "12px",
                            lg: "14px",
                            xl: "15px",
                          }}
                        >
                          {nm}
                        </Text>
                      </a>
                    );
                  })}
              </Flex>
            </Flex>
          </>
        );
      }
    );
  };

  return (
    <Container>
      <Flex
        w={{ base: "90%", md: "90%", lg: "80%" }}
        gap="30px"
        flexDirection="column"
        alignItems="center"
      >
        <Header name="Wohnungen" />
        {renderData()}
      </Flex>
    </Container>
  );
};

export default Wohnungen;
