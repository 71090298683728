import React from "react";
import { IData } from "../../App";
import { Flex, Box, Image } from "@chakra-ui/react";
import Header from "../shared/Header";
import { Link } from "react-router-dom";

const Gallery: React.FunctionComponent<IData> = (props) => {
  const renderImages = () => {
    return props.data?.gallery._image.map((image: string, index: number) => {
      let x: number = 0;
      for (let i = 0; i < props.data?.gallery._image.length; i++) {
        if (index < 6) x = index;
        if (index > 6 * i) x = index - 6 * i;
      }

      return (
        index < 9 && (
          <Box
            overflow="hidden"
            key={index}
            w={{
              base: `${
                x < 6
                  ? x === 0
                    ? "100%"
                    : x === 1 || x === 2
                    ? "calc(50% - 3px)"
                    : (x === 3 || x === 4 || x === 5) && "calc(33% - 3px)"
                  : x % 6 === 0
                  ? "100%"
                  : x % 7 === 0 || x % 8 === 0
                  ? "calc(50% - 3px)"
                  : x % 9 === 0 ||
                    x % 10 === 0 ||
                    (x % 11 === 0 && "calc(33% - 3px)")
              }`,
              md: `${
                x < 6
                  ? x === 0
                    ? "100%"
                    : x === 1 || x === 2
                    ? "calc(50% - 5px)"
                    : (x === 3 || x === 4 || x === 5) && "calc(33% - 5px)"
                  : x % 6 === 0
                  ? "100%"
                  : x % 7 === 0 || x % 8 === 0
                  ? "calc(50% - 5px)"
                  : x % 9 === 0 ||
                    x % 10 === 0 ||
                    (x % 11 === 0 && "calc(33% - 5px)")
              }`,
              lg: `${
                x < 6
                  ? x === 0
                    ? "100%"
                    : x === 1 || x === 2
                    ? "calc(50% - 10px)"
                    : (x === 3 || x === 4 || x === 5) && "calc(33% - 10px)"
                  : x % 6 === 0
                  ? "100%"
                  : x % 7 === 0 || x % 8 === 0
                  ? "calc(50% - 10px)"
                  : x % 9 === 0 ||
                    x % 10 === 0 ||
                    (x % 11 === 0 && "calc(33% - 10px)")
              }`,
            }}
            h={`${
              x < 6
                ? x === 0
                  ? "400px"
                  : x === 1 || x === 2
                  ? "300px"
                  : (x === 3 || x === 4 || x === 5) && "270px"
                : x % 6 === 0
                ? "400px"
                : x % 7 === 0 || x % 8 === 0
                ? "300px"
                : x % 9 === 0 || x % 10 === 0 || (x % 11 === 0 && "270px")
            }`}
          >
            <Image
              className="text-hover"
              h="100%"
              w="100%"
              objectFit="cover"
              src={image}
              key={index}
            />
            ;
          </Box>
        )
      );
    });
  };
  return (
    <Flex
      flexDirection="column"
      w="100%"
      gap="20px"
      justifyContent="center"
      alignItems="center"
    >
      <Header name="Galerie" />
      <Flex
        padding={{ base: "0px", md: "0 50px", lg: "0 70px", xl: "0 120px" }}
        flexWrap="wrap"
        gap={{ base: "5px", md: "10px", xl: "20px" }}
        w="100%"
        justifyContent="center"
        alignItems="flex-start"
      >
        {renderImages()}
      </Flex>
      <Link to="/gallery">
        <Box
          marginTop="10px"
          fontSize={{ base: "14px", md: "18px", lg: "24px" }}
          fontStyle="italic"
          borderBottom="1px #2D84B2 solid "
          className="text-hover"
          color="#2D84B2"
          cursor="pointer"
        >
          Mehr Zeigen
        </Box>
      </Link>
    </Flex>
  );
};

export default Gallery;
